import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Col, Container, Row } from "react-bootstrap";

import Anchor from "../elements/Anchor";
import { handleize } from "../../utilities";

import "../../styles/components/slices/brand.scss";

const Brand = ({ slice }) => {
  if (!slice) return null;

  const {
    logo,
    logo_decorated: logoDecorated,
    name,
    tagline,
    about,
    fashionworx,
  } = slice.primary;

  return (
    <article className="brand my-md-5 py-5" id={handleize(name)}>
      <figure>
        {slice.items.length > 1 ? (
          <Swiper
            className="swiper-slides-auto"
            modules={[Navigation]}
            slidesPerView={1}
            loop
            loopedSlides={slice.items.length}
            navigation
            grabCursor
            autoHeight
            breakpoints={{
              1024: {
                slidesPerView: "auto",
              },
            }}
          >
            {slice.items.map((item) => (
              <SwiperSlide
                key={item.slider_image.gatsbyImageData.placeholder.fallback}
              >
                <GatsbyImage
                  image={item.slider_image.gatsbyImageData}
                  alt={item.slider_image.alt || ""}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          slice.items.length !== 0 && (
            <GatsbyImage
              image={slice.items[0].slider_image.gatsbyImageData}
              alt={slice.items[0].slider_image.alt || ""}
              className="w-100"
            />
          )
        )}
      </figure>
      <Container>
        <Row>
          <Col md="3">
            <div
              // eslint-disable-next-line prettier/prettier
              className={`text-center${!logoDecorated.gatsbyImageData ? " brand__logo" : ""}`}
            >
              <GatsbyImage
                image={logoDecorated.gatsbyImageData || logo.gatsbyImageData}
                alt={logoDecorated.alt || logo.alt || name || ""}
              />
            </div>
          </Col>
          <Col md="9">
            <header className="mb-5 mt-md-5 pt-md-5 text-center text-md-start">
              <h2 className="fw-bold">{name}</h2>
              <p className="h4">{tagline}</p>
            </header>
            <section className="mb-4">
              <h3 className="header-label text-center text-md-start">ABOUT</h3>
              <div className="basic-text">
                <PrismicRichText field={about.richText} />
              </div>
            </section>
            <section>
              {fashionworx.richText.length > 0 &&
                fashionworx.richText[0]?.text && (
                  <>
                    <h3 className="header-label text-center text-md-start">
                      FASHIONWORX
                    </h3>
                    <div className="basic-text">
                      <PrismicRichText field={fashionworx.richText} />
                    </div>
                  </>
                )}
              <div className="text-center text-md-start">
                <Button
                  as={Anchor}
                  variant="dark"
                  href="/contact"
                  className="mt-4 px-4"
                >
                  CONTACT US
                </Button>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Brand;
