import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import Brand from "../components/slices/Brand";
import Anchor from "../components/elements/Anchor";

import { constructActiveDoc, handleize } from "../utilities";

import "../styles/pages/division.scss";

const Division = ({ data }) => {
  if (!data) return null;

  const { prismicSettings: settings, prismicDivision: page } = data;
  const {
    title,
    image,
    image_mobile: imageMobile,
    header,
    header_label: label,
    description,
    body,
  } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          image={image}
          imageMobile={imageMobile}
        />
        <Container as="section" className="my-5">
          <Row>
            <Col
              lg="4"
              className="division__header py-4 py-lg-5 text-center text-lg-start"
            >
              <div className="header-label mb-4 mb-lg-0 text-fire">{label}</div>
              <PrismicRichText field={header.richText} />
            </Col>
            <Col lg="8" className="py-4 py-lg-5 text-center text-lg-start">
              <div className="basic-text">
                <PrismicRichText field={description.richText} />
              </div>
              <div className="mt-4">
                {body.map((brand) => {
                  if (brand.slice_type === "brand") {
                    return (
                      <Anchor
                        href={`#${handleize(brand.primary.name)}`}
                        title={brand.primary.name}
                        key={brand.id}
                      >
                        <GatsbyImage
                          image={brand.primary.logo.gatsbyImageData}
                          alt={brand.primary.logo.alt || ""}
                          className="division__logo"
                        />
                      </Anchor>
                    );
                  }
                  return null;
                })}
              </div>
            </Col>
          </Row>
        </Container>
        {body.map((brand) => (
          <Brand slice={brand} key={brand.id} />
        ))}
      </Layout>
    </>
  );
};

export const query = graphql`
  query divisionQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    prismicDivision(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicDivisionFragment
    }
  }
`;

export default Division;
